<template>
    <div>
        <div class="level">
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title is-4">
                        {{ $t("Interface.DoorProvider.List.Title") }}
                    </h1>
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <button
                        type="submit"
                        class="button is-theme"
                        @click.prevent="$router.push({ name: 'DoorProvider-Add' })"
                    >
                        {{ $t("Interface.DoorProvider.List.AddButton") }}
                    </button>
                </div>
            </div>
        </div>

        <b-table
            :data="DoorProviders"
            :striped="true"
            :hoverable="true"
            :loading="IsLoading"
            icon-pack="fa"
        >
            <b-table-column v-slot="props" field="doorProvider" :label="$t('Interface.DoorProvider.Provider')" sortable>
                {{ props.row.doorProvider }}
            </b-table-column>
            <b-table-column v-slot="props" field="publicKey" :label="$t('Interface.DoorProvider.PublicKey')" sortable>
                {{ props.row.publicKey }}
            </b-table-column>
            <b-table-column v-slot="props" field="endpoint" :label="$t('Interface.DoorProvider.Endpoint')" sortable>
                {{ props.row.endpoint }}
            </b-table-column>
            <b-table-column v-slot="props" :label="$t('Interface.DoorProvider.List.Actions')">
                <router-link
                    v-if="props.row.doorProvider !== 'IronLogic'"
                    class="has-text-info"
                    :to="{ name: 'DoorProvider-Edit', params: { id: props.row.doorProviderCredId } }"
                >
                    <b-tooltip :label="$t('Interface.Button.Edit')" type="is-info" animated>
                        <font-awesome-icon :icon="['fas', 'pen-square']" size="lg" class="is-info" />
                    </b-tooltip>
                </router-link>
                <router-link
                    class="has-text-danger"
                    style="margin-left:0.5rem;"
                    :to="{ name: 'DoorProvider-Delete', params: { id: props.row.doorProviderCredId } }"
                >
                    <b-tooltip :label="$t('Interface.Button.Delete')" type="is-danger" animated>
                        <font-awesome-icon :icon="['fas', 'times-circle']" size="lg" class="is-danger" />
                    </b-tooltip>
                </router-link>
            </b-table-column>
            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>{{ $t("Interface.Door.List.Empty") }}</p>
                    </div>
                </section>
            </template>
        </b-table>
        <div class="buyhardware">
            <h4 class="title is-4">{{ $t("Interface.DoorProvider.Buy.Title") }}</h4>
            <div class="columns is-multiline has-text-centered">
                <!-- <div class="buyhardware-item column is-4-tablet is-2-widescreen">
                    <div class="box">
                        <img src="https://www.ausweis.io/media/files/Ausweis_logo_bg.png" class="buyhardware-item-img">
                        <p class="subtitle is-4">Ausweis</p>
                        <a href="https://www.ausweis.io/en/device/" target="_blank" class="buyhardware-item-link button is-theme">
                            {{ $t("Interface.Button.Buy") }}
                        </a>
                    </div>
                </div> -->
                <div class="buyhardware-item column is-4-tablet is-3-widescreen">
                    <div class="box">
                        <img src="https://simplegate.ru/wp-content/uploads/2018/08/logobrowser.png" class="buyhardware-item-img">
                        <p class="subtitle is-4">Simplegate</p>
                        <a href="https://simplegate.ru/controllers/" target="_blank" class="buyhardware-item-link button is-theme">
                            {{ $t("Interface.Button.Buy") }}
                        </a>
                    </div>
                </div>
                <div class="buyhardware-item column is-4-tablet is-3-widescreen">
                    <div class="box">
                        <font-awesome-icon
                            icon="cogs"
                            size="6x"
                            class="buyhardware-item-img has-text-primary"
                        />
                        <p class="subtitle is-4">Custom</p>
                        <a
                            href="https://bitbucket.org/gotollc/alldoors_public_api_docs/src/master/"
                            target="_blank"
                            class="buyhardware-item-link button is-theme"
                        >
                            {{ $t("Interface.Button.FindOut") }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingState from "@/mixins/LoadingState"
import { DOORPROVIDER_LIST_REQUEST } from "@/store/actions/doorprovider"

export default {
    name: "DoorProviderList",
    mixins: [LoadingState],
    computed: {
        DoorProvidersAmount() {
            return this.$store.getters.DOORS_AMOUNT
        },
        DoorProviders() {
            return this.$store.state.DoorProvider.List
        }
    },
    mounted() {
        if (!this.$store.getters.IS_DOORPROVIDERS_LOADED) {
            this.switchLoading()
            this.$store.dispatch(DOORPROVIDER_LIST_REQUEST)
                .finally(() => this.switchLoading())
        }
    },
    metaInfo() {
        return {
            title: this.$t("Interface.DoorProvider.List.Title")
        }
    }
}
</script>
